import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { SignInDataForm } from '../../../../types';
import {
  ButtonWithLoadingSpinner,
  RoundedInputWithValidation,
} from '../../../../components';
import { useAuthenticate } from '../../../../hooks';
import {
  AuthValidationMessages,
  EmailPropertyValidation,
  PassPropertyValidation,
  sanitizeEmail,
} from '../../../../shared/constants';
import {
  validateEmailFormat,
  validateWhiteSpaces,
} from '../../../../shared/util';

type Props = {
  handleToggleClick: () => void;
};
export default function LoginForm({ handleToggleClick }: Props): JSX.Element {
  const {
    signIn,
    error: signInError,
    loading: signInLoading,
  } = useAuthenticate();
  /* onTouched - validation will trigger on the first blur event. After that, it will trigger on every change event. */
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<SignInDataForm>({ mode: 'onTouched' });

  /* navigation to contractor routes will be handled by
   * public and private routes components */
  const handleSignIn = handleSubmit(async (data) => {
    // Sanitize the email before sending it to Firestore
    data.email = sanitizeEmail(data.email);
    await signIn(data);
  });

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {}, [signInError, signInLoading]);

  return (
    <form
      className='bg-white bg-opacity-[.65] backdrop-blur-[2px] shadow-2xl rounded-2xl flex flex-col items-center w-[90%] sm2:w-[500px] lg:min-w-[550px] xl:min-w-[600px] py-20 mb-4'
      onSubmit={handleSignIn}
    >
      <div className='flex flex-col h-full w-[80%] m-4 gap-8'>
        <div className='login-page-form-title'>
          <h1 className='text-4xl font-bold'>Login</h1>
        </div>
        {/* Login Input */}
        <div>
          {/* Email Address Input */}
          <RoundedInputWithValidation
            label='Email'
            placeholder='ex: user@email.com'
            error={errors.email?.message}
            {...register('email', {
              required: AuthValidationMessages.REQUIRED,
              validate: {
                noLeadingTrailingSpaces: validateWhiteSpaces,
                invalidEmailFormat: validateEmailFormat,
              },
              maxLength: {
                value: EmailPropertyValidation.MAX_LENGTH,
                message:
                  AuthValidationMessages.MAX_LENGTH_OF_50 /* ERROR MSG */,
              },
            })}
          />
        </div>
        {/* Password Input */}
        <div>
          <RoundedInputWithValidation
            label='Password'
            placeholder='********'
            type='password'
            error={errors.password?.message}
            {...register('password', {
              required: AuthValidationMessages.PASSWORD_REQUIRED,
              minLength: {
                value: PassPropertyValidation.MIN_LENGTH,
                message: AuthValidationMessages.PASSWORD_MIN_LENGTH,
              },
              maxLength: {
                value: PassPropertyValidation.MAX_LENGTH,
                message: AuthValidationMessages.PASSWORD_MAX_LENGTH,
              },
            })}
          />
          {/* error from firebase while trying to sign in to account */}
          {signInError && (
            <div className='flex flex-col pt-2'>
              <span className='text-sm text-red-500'>{signInError}</span>
            </div>
          )}
        </div>
        {/* Sign in and register link */}
        <div className='flex flex-col items-center gap-16'>
          {/* Sign in button */}
          <div className='flex flex-col w-full h-auto'>
            <ButtonWithLoadingSpinner
              loading={signInLoading}
              disabled={signInLoading}
              message='Log in'
            />
          </div>
          {/* Register link and reset password form */}
          <div className='flex flex-col gap-2.5 text-center'>
            {/* Register link */}
            <Link to='/select-account'>
              <p className='text-md xs:text-lg font-semibold'>
                Create an account
              </p>
            </Link>
            {/* password form */}
            <div
              className='text-md xs:text-lg font-semibold hover:cursor-pointer'
              onClick={handleToggleClick}
            >
              Forgot your password?
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
