import { Timestamp } from 'firebase/firestore';

export type SignUpDataForm = {
  email: string;
  password: string;
  confirm_password: string;
  tos_privacy_checkbox: boolean;
};

export type SignInDataForm = {
  email: string;
  password: string;
};

export interface company {
  name: string;
  address: address;
  contact: contact;
}

export interface customer {
  firstName: string;
  lastName: string;
  address: address;
  contact: contact;
}

export interface address {
  street?: string;
  city?: string;
  state?: string;
  zipcode?: string;
}

export interface contact {
  phoneNumber: string;
  email: string;
}

export interface StandardEstimateDataForm {
  type: string;
  createdAt: Timestamp;
  jobDetails: string;
  taxPercent: number;
  company: company;
  customer: customer;
  costs: {
    description: string;
    quantity: number;
    price: number;
  }[];
}

export interface StandardEstimateDTO {
  id: string;
  data: StandardEstimateDataForm;
}

export const StandardEstimateContractType = 'StandardEstimate';
/* END - StandardEstimateDataForm */

/* HelpAndSupportDataForm */
export type HelpAndSupportDataForm = {
  name: string;
  email: string;
  phoneNumber: string;
  helpMessage: string;
};
/* END - HelpAndSupportDataForm */

/* FIREBASE */
export type Role = 'CONTRACTOR' | 'CUSTOMER' | 'DEFAULT';

export type CustomerInfo = {
  id: string;
  username: string;
  email: string;
  role: Role;
  createdAt: Timestamp;
  updatedAt?: Timestamp;
};

export type ContractorInfo = {
  id: string;
  username: string;
  email: string;
  role: Role;
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  company?: company;
};

export type SubscriptionInfo = {
  subscriptionId?: string;
  current_period_end?: number;
  isCancelAtPeriodEnd?: boolean;
  status?: string;
  created?: number;
  current_period_start?: number;
};
/* END - FIREBASE */

/* SETTINGS PAGE */
export type StripeContractorSubscription = {
  status: 'active' | 'cancelled';
  // active properties
  planPrice?: number;
  email?: string;
  nextBillDate?: string;
  lastFourCardNumbers?: string;
  cardExpirationDate?: string;
  // cancelled properties
  subscriptionAccessEnds?: string;
  planType?: string;
};
/* SETTINGS PAGE */
