import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { company, ContractorInfo } from '../../../../../../types';
import InputWithBottomBorder from '../../../../../../components/InputWithBottomBorder/InputWithBottomBorder';
import { FormErrorElementContainer } from '../../../../../../components';
import { useAuthContext } from '../../../../../../state/auth-context';
import { toast } from 'react-toastify';
import { UpdateContractorCompanyInformation } from '../../ContractorSettingsPageService';
import { HeaderWithActionButtons } from '../Shared/HeaderWithActionButtons/HeaderWithActionButtons';
import {
  AuthValidationMessages,
  CityPropertyValidation,
  CityPropertyValidationMessages,
  CityRegexValidation,
  EmailPropertyValidation,
  NamePropertyValidation,
  NamePropertyValidationMessages,
  StateCodeRegexValidation,
  StatePropertyValidation,
  StatePropertyValidationMessages,
  StreetPropertyValidation,
  StreetPropertyValidationMessages,
  StreetRegexValidation,
  ZipCodePropertyValidation,
  ZipCodePropertyValidationMessages,
  ZipCodeRegexValidation,
} from '../../../../../../shared/constants';
import {
  escapeAllowedSpecialChars,
  unescapeAllowedSpecialChars,
  validateEmailFormat,
  validateOnlySpecialCharacters,
  validatePhoneFormat,
  validateWhiteSpaces,
  validateWhiteSpaces_V2,
} from '../../../../../../shared/util';

const requiredPrompt = 'Required.';

type CompanyInformationFormProps = {
  contractorInfo: ContractorInfo;
};

export function CompanyInformationForm({
  contractorInfo,
}: CompanyInformationFormProps): JSX.Element {
  const [isEditing, setIsEditing] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    trigger,
  } = useForm<company>({
    mode: 'onTouched',
    defaultValues: {
      name: unescapeAllowedSpecialChars(contractorInfo.company?.name || ''),
      contact: {
        email: contractorInfo.company?.contact.email || '',
        phoneNumber: contractorInfo.company?.contact.phoneNumber || '',
      },
      address: {
        street: unescapeAllowedSpecialChars(
          contractorInfo.company?.address.street || ''
        ),
        city: unescapeAllowedSpecialChars(
          contractorInfo.company?.address.city || ''
        ),
        state: contractorInfo.company?.address.state || '',
        zipcode: contractorInfo.company?.address.zipcode || '',
      },
    },
  });

  const {
    authState: { userInfo },
  } = useAuthContext();

  useEffect(() => {
    // User can edit form in two ways:
    // 1. Clicking on the edit button
    // 2. Changing the input values triggers isDirty
    setIsEditing(isDirty);
  }, [isDirty]);

  const onSubmitContactInformation = async (data: company) => {
    // Sanitize the company name input
    data.name = escapeAllowedSpecialChars(data.name);
    data.address.street = escapeAllowedSpecialChars(data.address.street ?? '');
    data.address.city = escapeAllowedSpecialChars(data.address.city ?? '');

    // isValid rules are applied to the unescaped strings
    // if statements are to validate the length after escaping the characters.
    // since we may add additional characters to the input
    // encoding apostrophe and ampersand characters adds 9 characters to the string
    if (data.name.length > NamePropertyValidation.MAX_LENGTH + 10) {
      toast.error('An error occurred updating Company name');
      return;
    }
    if (data.address.street.length > StreetPropertyValidation.MAX_LENGTH + 10) {
      toast.error('An error occurred updating Street address');
      return;
    }
    if (data.address.city.length > CityPropertyValidation.MAX_LENGTH + 10) {
      toast.error('An error occurred updating City');
      return;
    }

    // check form has changed to save changes
    if (!isDirty) {
      // Toggle back to edit mode
      setIsEditing(false);
      return;
    }

    // validate form
    const isValid = await trigger();
    if (!isValid) {
      return;
    }

    const savingContactInfoToast = toast('Saving company information...', {
      type: 'info',
    });
    const contractorId = userInfo?.id as string;

    const success = await UpdateContractorCompanyInformation(
      contractorId,
      data
    );

    if (success) {
      toast.update(savingContactInfoToast, {
        render: 'Company information updated successfully.',
        type: 'success',
        autoClose: 5000,
      });
      // keeps current form values after successful submission
      reset({}, { keepValues: true });
    } else {
      toast.update(savingContactInfoToast, {
        render:
          'An error occurred while updating company information. Please try again later.',
        type: 'error',
        autoClose: 5000,
      });
      resetForm();
    }

    // Toggle back to edit mode
    setIsEditing(false);
  };

  const resetForm = () => {
    reset({
      name: unescapeAllowedSpecialChars(contractorInfo.company?.name || ''),
      contact: {
        email: contractorInfo.company?.contact.email || '',
        phoneNumber: contractorInfo.company?.contact.phoneNumber || '',
      },
      address: {
        street: unescapeAllowedSpecialChars(
          contractorInfo.company?.address.street || ''
        ),
        city: unescapeAllowedSpecialChars(
          contractorInfo.company?.address.city || ''
        ),
        state: contractorInfo.company?.address.state || '',
        zipcode: contractorInfo.company?.address.zipcode || '',
      },
    });
  };

  const handleCancel = () => {
    resetForm();
    setIsEditing(false);
  };

  return (
    <>
      {/* Header */}
      <HeaderWithActionButtons
        title={'Company Information'}
        isEditing={isEditing}
        onEdit={() => setIsEditing(true)}
        onSave={handleSubmit(onSubmitContactInformation)}
        onCancel={handleCancel}
      />
      {/* Note to contractors */}
      <div className='flex flex-row gap-1'>
        <div className='flex flex-col shrink-0 mt-[2px]'>
          <img
            className='w-[20px] h-[20px]'
            src='/assets/shared/icons/ExclamationCircle.svg'
            alt='exclamation icon'
          />
        </div>
        <div className='flex flex-col'>
          <span className='text-[#676d73] opacity-80'>
            Note: This information will be used in your invoices.
          </span>
        </div>
      </div>
      {/* Company Info Form Inputs */}
      <form
        className='flex flex-col gap-4'
        onSubmit={handleSubmit(onSubmitContactInformation)}
      >
        {/* company email */}
        <div className='grid grid-cols-1'>
          <InputWithBottomBorder
            {...register('contact.email', {
              required: requiredPrompt,
              validate: {
                noLeadingTrailingSpaces: validateWhiteSpaces,
                invalidEmailFormat: validateEmailFormat,
              },
              maxLength: {
                value: EmailPropertyValidation.MAX_LENGTH,
                message:
                  AuthValidationMessages.MAX_LENGTH_OF_50 /* ERROR MSG */,
              },
            })}
            inputTitle={'Email'}
            placeholder={'example@email.com'}
            type='text'
            aria-label={'Input for email'}
          />
          {errors.contact?.email?.message && (
            <FormErrorElementContainer
              inputErrorMsg={errors.contact.email?.message}
            />
          )}
        </div>
        {/* company name */}
        <div className='grid grid-cols-1'>
          <InputWithBottomBorder
            {...register('name', {
              required: requiredPrompt,
              maxLength: {
                value: NamePropertyValidation.MAX_LENGTH,
                message:
                  NamePropertyValidationMessages.MAX_LENGTH_OF_50 /* ERROR MSG */,
              },
              validate: {
                noLeadingTrailingSpaces: validateWhiteSpaces,
                onlySpecialCharacters: validateOnlySpecialCharacters,
              },
            })}
            inputTitle={'Company Name'}
            iconSource={'/assets/shared/icons/profile_icon.svg'}
            placeholder={'Company Name'}
            type='text'
            aria-label={'Input for company name'}
          />
          {errors.name?.message && (
            <FormErrorElementContainer inputErrorMsg={errors.name?.message} />
          )}
        </div>
        {/* company phone */}
        <div className='grid grid-cols-1'>
          <InputWithBottomBorder
            {...register('contact.phoneNumber', {
              required: requiredPrompt,
              validate: {
                noLeadingTrailingSpaces: validateWhiteSpaces,
                invalidPhoneFormat: validatePhoneFormat,
              },
            })}
            inputTitle={'Phone'}
            iconSource={'/assets/shared/icons/Phone.svg'}
            placeholder={'214-000-0000'}
            type='text'
            aria-label={'Input for Phone Number'}
          />
          {errors.contact?.phoneNumber?.message && (
            <FormErrorElementContainer
              inputErrorMsg={errors.contact.phoneNumber?.message}
            />
          )}
        </div>
        {/* company address */}
        <div className='grid grid-cols-1 sm2:grid-cols-2 gap-4'>
          <div>
            <InputWithBottomBorder
              {...register('address.street', {
                required: requiredPrompt,
                pattern: {
                  value: StreetRegexValidation.PATTERN,
                  message:
                    StreetPropertyValidationMessages.ONLY_SPECIAL_CHARACTERS,
                  /* ERROR MSG */
                },
                maxLength: {
                  value: StreetPropertyValidation.MAX_LENGTH,
                  message:
                    StreetPropertyValidationMessages.MAX_LENGTH_OF_50 /* ERROR MSG */,
                },
                validate: {
                  noLeadingTrailingSpaces: validateWhiteSpaces_V2,
                },
              })}
              inputTitle={'Street'}
              iconSource={'/assets/shared/icons/LocationMarker.svg'}
              placeholder={'#123 Street Name'}
              type='text'
              aria-label={'Input for Street'}
            />
            {errors.address?.street?.message && (
              <FormErrorElementContainer
                inputErrorMsg={errors.address?.street?.message}
              />
            )}
          </div>
          <div>
            <InputWithBottomBorder
              {...register('address.city', {
                required: requiredPrompt,
                pattern: {
                  value: CityRegexValidation.PATTERN,
                  message:
                    CityPropertyValidationMessages.ONLY_SPECIAL_CHARACTERS,
                  /* ERROR MSG */
                },
                maxLength: {
                  value: CityPropertyValidation.MAX_LENGTH,
                  message:
                    CityPropertyValidationMessages.MAX_LENGTH_OF_50 /* ERROR MSG */,
                },
                validate: {
                  noLeadingTrailingSpaces: validateWhiteSpaces_V2,
                },
              })}
              inputTitle={'City'}
              placeholder={'Dallas'}
              type='text'
              aria-label={'Input for City'}
            />
            {errors.address?.city?.message && (
              <FormErrorElementContainer
                inputErrorMsg={errors.address.city?.message}
              />
            )}
          </div>
          <div>
            <InputWithBottomBorder
              {...register('address.state', {
                required: requiredPrompt,
                pattern: {
                  value: StateCodeRegexValidation.PATTERN,
                  message:
                    StatePropertyValidationMessages.INCORRECT_FORMAT /* ERROR MSG */,
                },
                maxLength: {
                  value: StatePropertyValidation.MAX_LENGTH,
                  message:
                    StatePropertyValidationMessages.MAX_LENGTH_OF_30 /* ERROR MSG */,
                },
              })}
              inputTitle={'State'}
              placeholder={'Texas'}
              type='text'
              aria-label={'Input for State'}
            />
            {errors.address?.state?.message && (
              <FormErrorElementContainer
                inputErrorMsg={errors.address.state?.message}
              />
            )}
          </div>
          <div>
            <InputWithBottomBorder
              {...register('address.zipcode', {
                required: requiredPrompt,
                pattern: {
                  value: ZipCodeRegexValidation.PATTERN,
                  message: ZipCodePropertyValidationMessages.INCORRECT_FORMAT,
                  /* ERROR MSG */
                },
                maxLength: {
                  value: ZipCodePropertyValidation.MAX_LENGTH,
                  message:
                    ZipCodePropertyValidationMessages.MAX_LENGTH_OF_10 /* ERROR MSG */,
                },
              })}
              inputTitle={'Zipcode'}
              placeholder={'75000'}
              type='text'
              aria-label={'Input for Zipcode'}
            />
            {errors.address?.zipcode?.message && (
              <FormErrorElementContainer
                inputErrorMsg={errors.address.zipcode?.message}
              />
            )}
          </div>
        </div>
      </form>
    </>
  );
}
