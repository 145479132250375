import { db } from '../../../../firebase/config';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { contractorsCollection } from '../../../../shared/constants';
import {
  company,
  ContractorInfo,
  StripeContractorSubscription,
} from '../../../../types';
import { HttpHeaders, HttpMethod } from '../../../../shared/http';
import { getAuthToken } from '../../../../shared/util';

export const GetContractorInformation = async (contractorId: string) => {
  const docRef = doc(db, `${contractorsCollection}/${contractorId}`);
  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const contractorInfo = docSnap.data() as ContractorInfo;
      return contractorInfo;
    } else {
      // document does not exists
      return null;
    }
  } catch {
    // error occurred while getting document
    return null;
  }
};

export const UpdateContractorCompanyInformation = async (
  contractorId: string,
  companyDataUpdate: company
) => {
  const docRef = doc(db, `${contractorsCollection}/${contractorId}`);
  try {
    // add company data to the contractor document
    await updateDoc(docRef, {
      company: companyDataUpdate,
    });
    return true;
  } catch {
    return false;
  }
};

export async function getContractorSubscription() {
  // verify service url is set
  const url = process.env.REACT_APP_CONTRACTOR_SETTINGS_SUBS_URL;
  if (!url) {
    throw new Error('Service Url is not set');
  }
  // get auth token
  const token = await getAuthToken();

  const response = await fetch(url, {
    method: HttpMethod.GET,
    headers: {
      [HttpHeaders.CONTENT_TYPE]: HttpHeaders.APPLICATION_JSON,
      [HttpHeaders.AUTHORIZATION]: `Bearer ${token}`,
    },
  });

  // response with values means user has a subscription
  // response with values can also be active, or cancelled
  // response with null means user does not have a subscription
  switch (response.status) {
    case 200: {
      const data = (await response.json()) as StripeContractorSubscription;
      return data;
    }
    case 204: {
      return null;
    }
    default: {
      const errResponse = await response.json();
      const error = errResponse?.error;
      throw new Error(`Error fetching subscription: ${error}`);
    }
  }
}

export async function getPortalSession() {
  // verify service url is set
  const url = process.env.REACT_APP_CONTRACTOR_SETTINGS_UPDATE_SUBS_URL;
  if (!url) {
    throw new Error('Service Url is not set');
  }
  // get auth token
  const token = await getAuthToken();

  const response = await fetch(url, {
    method: HttpMethod.GET,
    headers: {
      [HttpHeaders.CONTENT_TYPE]: HttpHeaders.APPLICATION_JSON,
      [HttpHeaders.AUTHORIZATION]: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    return null;
  }

  const data = await response.json();
  return data;
}
