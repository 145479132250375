import { Timestamp } from 'firebase/firestore';
import {
  AuthValidationMessages,
  EmailRegexPattern,
  NamePropertyValidationMessages,
  NameRegexValidation,
  PhoneNumberPropertyValidationMessages,
  PhoneNumberRegexValidation,
  StreetPropertyValidationMessages,
} from './constants';
import { getAuth } from 'firebase/auth';

export const dateToday = new Date().toLocaleDateString('en-US');

// convert seconds and nanoseconds timestamp object to mm/dd/yyy date
export function getDateFromFirestoreTimestamp(timestamp: Timestamp) {
  const { seconds, nanoseconds } = timestamp;
  const fireBaseTime = new Date(seconds * 1000 + nanoseconds / 1000000);
  return fireBaseTime.toLocaleDateString();
}

type Cost = {
  description: string;
  price: number;
  quantity: number;
};

export function CalculateTotalAmount(
  costs: Cost[],
  taxPercent: number
): string {
  // Calculate the total cost by summing up the price * quantity for each item
  const totalCost = costs.reduce(
    (total, cost) => total + cost.price * cost.quantity,
    0
  );

  // Calculate the tax amount
  const taxAmount = totalCost * (taxPercent / 100);

  // Calculate the subtotal by adding the total cost and the tax amount
  const subTotal = totalCost + taxAmount;

  // Format the subtotal as a currency string with two decimal places
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  return formatter.format(subTotal);
}

export function escapeAllowedSpecialChars(input: string): string {
  const charMap: { [key: string]: string } = {
    '\u0027': '&#x27;', // apostrophe
    '&': '&amp;',
  };

  return input.replace(/['&]/g, (char) => charMap[char]);
}

export function unescapeAllowedSpecialChars(input: string): string {
  const charMap: { [key: string]: string } = {
    '&#x27;': '\u0027', // apostrophe
    '&amp;': '&',
  };

  return input.replace(/&#x27;|&amp;/g, (char) => charMap[char]);
}

/******** COMPANY EMAIL VALIDATION LOGIC *********/
export function validateEmailFormat(value: string): true | string {
  return (
    EmailRegexPattern.PATTERN.test(value) ||
    AuthValidationMessages.INCORRECT_FORMAT
  );
}

/******** END - COMPANY EMAIL VALIDATION LOGIC *********/

/******** COMPANY NAME VALIDATION LOGIC *********/
export function validateOnlySpecialCharacters(value: string): true | string {
  return (
    NameRegexValidation.PATTERN.test(value) ||
    NamePropertyValidationMessages.ONLY_SPECIAL_CHARACTERS
  );
}
/******** END - COMPANY NAME VALIDATION LOGIC *********/

/******** COMPANY NAME VALIDATION LOGIC *********/
// validate for leading and trailing spaces
export const validateWhiteSpaces = (value: string): true | string => {
  return !/^\s|\s$/.test(value) || 'No leading or trailing spaces allowed.';
};
/******** END - COMPANY NAME VALIDATION LOGIC *********/

/******** COMPANY PHONE VALIDATION LOGIC *********/
export function validatePhoneFormat(value: string): true | string {
  return (
    PhoneNumberRegexValidation.PATTERN.test(value) ||
    PhoneNumberPropertyValidationMessages.INCORRECT_FORMAT
  );
}
/******** END - COMPANY PHONE VALIDATION LOGIC *********/

/******** COMPANY STREET/CITY VALIDATION LOGIC *********/
// version 2 is used because of strings are nullables
export const validateWhiteSpaces_V2 = (
  value: string | undefined
): true | string => {
  return (
    !/^\s|\s$/.test(value ?? '') ||
    StreetPropertyValidationMessages.NO_LEADING_OR_TRAILING_SPACES
  );
};
/******** END - COMPANY STREET/CITY VALIDATION LOGIC *********/

/******** GET FRESH AUTH TOKEN *********/
export async function getAuthToken() {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User is not authenticated');
  }
  /***********************************
   * use cached token if valid, otherwise refreshes it.
   * ***********************************/
  return user.getIdToken();
}
/******** END - GET FRESH AUTH TOKEN *********/
