import React from 'react';
import { LandingPage, LogInPage, SelectAccountPage } from './pages/Auth';
import {
  AboutUsPage,
  ErrorPage,
  PricingPlansPage,
  PrivacyPolicyPage,
  TermsOfServicePage,
} from './pages/Shared';
import { Route, Routes } from 'react-router-dom';
import ContractorRoutes from './routes/Contractor/ContractorRoutes';
import CustomerRoutes from './routes/Customer/CustomerRoutes';
import { PublicRoute } from './routes/PublicRoute';
import { SuccessPage } from './pages/Shared/Stripe/SuccessPage/SuccessPage';
import { CancelPage } from './pages/Shared/Stripe/CancelPage/CancelPage';

function App(): JSX.Element {
  return (
    <div className='w-full min-h-screen h-auto flex flex-col items-center justify-center'>
      <Routes>
        <Route path='/' element={<LandingPage />} />
        {/* PREVENT AUTHENTICATED USER FROM RE-LOGGING IN AGAIN */}
        {/* IF USER IS AUTHENTICATED IT WILL NAVIGATE TO LANDING PAGE */}
        <Route path='/log-in' element={<PublicRoute />}>
          {/* index - is used to specify that the component should be rendered when the parent route's path matches the URL exactly */}
          <Route index element={<LogInPage />} />
        </Route>
        <Route path='/select-account' element={<PublicRoute />}>
          {/* index - is used to specify that the component should be rendered when the parent route's path matches the URL exactly */}
          <Route index element={<SelectAccountPage />} />
        </Route>
        {/*<Route path='/sign-up' element={<PublicRoute />}>*/}
        {/* index - is used to specify that the component should be rendered when the parent route's path matches the URL exactly */}
        {/*  <Route index element={<SignUpPage />} />*/}
        {/*</Route>*/}
        {/* Contractor */}
        <Route path='/contractor/*' element={<ContractorRoutes />} />
        {/* Customer */}
        <Route path='/customer/*' element={<CustomerRoutes />} />
        {/* Shared */}
        <Route path='/about-us' element={<AboutUsPage />} />
        <Route path='/terms' element={<TermsOfServicePage />} />
        <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
        {/*<Route path='/help' element={<HelpAndSupportPage />} />*/}
        <Route path='/pricing-plans' element={<PricingPlansPage />} />
        <Route path='/success' element={<SuccessPage />} />
        <Route path='/cancel' element={<CancelPage />} />
        <Route path='*' element={<ErrorPage />} />
      </Routes>
    </div>
  );
}

export default App;
