export const pricingPlans = [
  {
    name: 'Basic',
    price: '$0/month',
    features: [
      'Manage and share contracts via email',
      'Limited to 3 contracts per month',
      'Mobile Access',
    ],
  },
  {
    name: 'Ultimate',
    price: '$9.99/month',
    features: [
      'Everything in basic',
      'Access to Unlimited contracts per month',
      'More features to come',
    ],
  },
];
