import React, { useEffect, useState } from 'react';
import {
  Footer,
  FormContainer,
  Navbar,
  NavbarContractor,
} from '../../../components';
import { PricingCard } from './components/PricingCard/PricingCard';
import { PricingPlansHeader } from './components/PricingPlansHeader/PricingPlansHeader';
import { useAuthContext } from '../../../state/auth-context';
import { useNavigate } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { pricingPlans } from '../../../shared/pricingPlans';
import { toast, ToastContainer } from 'react-toastify';
import { loadStripe } from '@stripe/stripe-js';

const functions = getFunctions();

interface StripeSessionResponse {
  status: string;
  id?: string;
  message?: string;
}

export function PricingPlansPage(): JSX.Element {
  const [navbarType, setNavbarType] = useState('');
  const {
    authState: { authUser, userInfo },
  } = useAuthContext();
  const navigate = useNavigate();

  /*****************************/
  /* USE EFFECTS */
  /****************************/
  useEffect(() => {
    if (authUser && userInfo) {
      switch (userInfo.role) {
        case 'CONTRACTOR':
          setNavbarType('CONTRACTOR');
          break;
        default:
          setNavbarType('');
          break;
      }
    } else {
      setNavbarType('');
    }
  }, [authUser, userInfo]);
  /* END - USE EFFECTS */

  const handleGetStartedClick = async (planName: string, amount: string) => {
    if (!authUser) {
      navigate('/log-in');
    } else if (planName === 'Basic') {
      navigate('/contractor/settings');
    } else {
      try {
        const createCheckoutSession = httpsCallable(
          functions,
          'stripeContractorSubsSession'
        );
        const response = (await createCheckoutSession({
          planName,
          amount: parseFloat(amount.replace('$', '')) * 100, // convert to cents
        })) as { data: StripeSessionResponse };

        // Check if the response indicates an error
        if (response.data.status === 'error') {
          // ERROR - Show a toast if there was an error in the backend
          toast.error(
            'Something went wrong. Please try again or contact support.'
          );
          return;
        }

        // Extract the Stripe session ID from the response
        const stripeSessionId = response.data?.id;

        if (stripeSessionId) {
          // Load the Stripe instance
          const stripe = await loadStripe(
            `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}` ?? ''
          );
          if (stripe) {
            // SUCCESS - Redirect to the Stripe checkout page with the session ID
            await stripe.redirectToCheckout({ sessionId: stripeSessionId });
          } else {
            // ERROR - Show a toast if Stripe.js has not loaded
            toast.error(
              'We are having trouble connecting to the payment system. Please try again later.'
            );
          }
        } else {
          // ERROR - Show a toast if stripeSessionId is not available from backend
          toast.error(
            'Unable to start the payment process. Please contact support.'
          );
        }
      } catch {
        toast.error('An unexpected error occurred. Please try again later.');
      }
    }
  };

  /* pricing pricingPlans can be uses for auth and non-auth users */
  return (
    <div id='pricing-plans-page' className='w-full h-screen flex flex-col'>
      <ToastContainer position={'top-center'} />
      {navbarType == 'CONTRACTOR' && <NavbarContractor />}
      {navbarType == '' && <Navbar />}
      <main className='flex-1 px-4 md:px-8 pt-20 pb-12'>
        <div className='flex flex-col items-center gap-20'>
          <PricingPlansHeader />
          <div className='grid md2:grid-cols-2 items-center gap-12'>
            {pricingPlans.map((plan, index) => (
              <FormContainer key={index}>
                <PricingCard
                  {...plan}
                  onGetStartedClick={() =>
                    handleGetStartedClick(plan.name, plan.price)
                  }
                />
              </FormContainer>
            ))}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
