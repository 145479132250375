import React from 'react';
import { Footer, FormContainer, HomeNavbar } from '../../../../components';
import { useNavigate } from 'react-router-dom';

export function SuccessPage(): JSX.Element {
  const navigate = useNavigate();

  return (
    <div id='success-page' className='w-full h-screen flex flex-col'>
      <HomeNavbar />
      {/* MAIN CONTAINER */}
      <main className='flex-1 pt-16 md:pt-20 pb-8 px-4 md:px-8'>
        {/* OUTER FORM CONTAINER FOR CENTERING */}
        <div className='w-full h-full flex flex-col justify-center items-center'>
          {/* FORM CONTAINER */}
          <div className='flex flex-col w-[90%] max-w-[800px] gap-8'>
            <FormContainer>
              <div className='flex flex-col items-center gap-5'>
                {/* SUCCESS ICON */}
                <img
                  src={'assets/pages/Shared/Stripe/blue-checkmark.png'}
                  alt='Success'
                  className='h-[200px] w-[200px]'
                />
                {/* SUCCESS HEADER */}
                <h1 className='font-light text-3xl sm:text-4xl md2:text-5xl text-center'>
                  Payment Success
                </h1>
                {/* SUCCESS MESSAGE */}
                <div className='flex flex-col items-center'>
                  <p className='font-light text-xl text-gray-600 hidden md2:flex'>
                    You are now able to start sending proposals to your clients.
                  </p>
                  <p className='font-light text-xl text-gray-600 text-center'>
                    We look forward to seeing your business grow.
                  </p>
                </div>
                {/* NAVIGATION BUTTON */}
                <div>
                  <button
                    type='button'
                    className='flex flex-row gap-1 items-center hover:opacity-70 hover:cursor-pointer font-base rounded-3xl text-center'
                    onClick={() => navigate('/')}
                  >
                    <span className='text-blueOne font-light text-xl hover:underline'>
                      Create an estimate
                    </span>
                    <img
                      className='h-[10px] w-[20px] hidden xs:block'
                      src='/assets/shared/icons/right-blue-arrow-icon.png'
                      alt='arrow-icon'
                    />
                  </button>
                </div>
              </div>
            </FormContainer>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
