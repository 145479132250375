import { collection, DocumentSnapshot } from 'firebase/firestore';
import { db } from './config';

export const contractorsRef = collection(db, 'contractors');
export const subscriptionRef = collection(db, 'subscription');

export const snapshotToDoc = <T>(doc: DocumentSnapshot) => {
  // as keyword is used to explicitly type cast a value to a different type
  const docData = doc.data() as T;
  // copy all properties of the document and add document id
  const docObject: T = {
    ...docData,
    id: doc.id,
  };

  return docObject;
};
