import React, { useEffect, useState } from 'react';
import {
  getContractorSubscription,
  getPortalSession,
} from '../../ContractorSettingsPageService';
import { StripeContractorSubscription } from '../../../../../../types';
import { HeaderAndContentSkeleton } from '../../../../../../components';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

export function SubscriptionManagement() {
  // Subscription State
  const [subscription, setSubscription] =
    useState<StripeContractorSubscription | null>();
  const [errorSubscription, setErrorSubscription] = useState<boolean>(false);
  const [loadingSubscription, setLoadingSubscription] =
    useState<boolean>(false);
  // Component Layout State
  const [userDoesNotHaveSubscription, setUserDoesNotHaveSubscription] =
    useState(false);
  const [userHasCancelledSubscription, setUserHasCancelledSubscription] =
    useState(false);
  // Customer Portal State
  const [loadingCustomerPortal, setLoadingCustomerPortal] =
    useState<boolean>(false);

  const handleFetch = async () => {
    setLoadingSubscription(true); // Set loading state to true before starting the fetch
    try {
      const subs = await getContractorSubscription();
      setSubscription(subs);
      setUserDoesNotHaveSubscription(subs === null);
      setUserHasCancelledSubscription(subs?.status === 'cancelled');
    } catch {
      setErrorSubscription(true);
      setSubscription(null);
    } finally {
      setLoadingSubscription(false); // Set loading state to false after fetch completes
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await handleFetch();
    };
    fetchData();
  }, []);

  const formatPrice = (price: number | null) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format((price ?? 0) / 100);

  const formatDate = (date: string) =>
    new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

  /* for all active, cancelled and no subscription */
  function PlanCard() {
    const formattedPrice = formatPrice(subscription?.planPrice ?? null);
    const planType = subscription?.status === 'active' ? 'Ultimate' : 'Basic';
    const contractLimitText =
      subscription?.status === 'active'
        ? 'Access to Unlimited contracts per month'
        : 'Limited to 3 contracts per month';

    return (
      <div className='flex flex-col bg-[#F5F6F8] rounded-2xl gap-6 px-4 py-10'>
        {/* plan name */}
        <div className='flex flex-col sm2:flex-row justify-between'>
          {/* plan type */}
          <span className='text-4xl sm:text-5xl font-extralight text-blueOne'>
            {planType}
          </span>
          {/* plan price */}
          <div className='flex flex-row items-end gap-2 flex-wrap'>
            <span className='text-black text-4xl font-light '>
              {formattedPrice}
            </span>
            <span className='text-[#6D727F] text-2xl font-light '>/ month</span>
          </div>
        </div>
        {/* plan description */}
        <span className='text-[#6D727F] text-xl xs:text-2xl font-light'>
          {contractLimitText}
        </span>
      </div>
    );
  }

  const handleUpdateSubscription = async () => {
    try {
      setLoadingCustomerPortal(true);

      const session = await getPortalSession();
      if (session && session?.url) {
        // check if the session url is valid
        const url = new URL(session.url);
        if (
          url.protocol === 'https:' &&
          url.hostname === 'billing.stripe.com'
        ) {
          // redirect to the customer portal
          // KEEP LOADING STATE UNTIL REDIRECT TO CUSTOMER PORTAL
          window.location.replace(session.url);
        } else {
          setLoadingCustomerPortal(false);
          toast.error(
            'Something went wrong going to portal. Please try again later.'
          );
        }
      } else {
        setLoadingCustomerPortal(false);
        toast.error('Unable to go to portal. Please try again later.');
      }
    } catch {
      setLoadingCustomerPortal(false);
      toast.error('An error occurred. Please try again later.');
    }
  };

  /* ACTIVE SUBSCRIPTION */
  function BillingInformation() {
    const email = subscription?.email ?? 'N/A';
    const nextBillDate = subscription?.nextBillDate
      ? formatDate(subscription.nextBillDate)
      : 'N/A';

    return (
      <div className='flex flex-col gap-4'>
        {/* sub header */}
        <h3 className='text-2xl font-normal'>Billing Information</h3>
        {/* billing information */}
        <div className='grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-0'>
          {/* email */}
          <div className='flex flex-col gap-2'>
            <div className='text-[#6D727F] text-xl font-light'>Email</div>
            <div className='text-black text-xl break-words'>{email}</div>
          </div>
          {/* bill date */}
          <div className='flex flex-col gap-2'>
            <div className='text-[#6D727F] text-xl font-light'>Bill Date</div>
            <div className='text-black text-xl'>{nextBillDate}</div>
          </div>
        </div>
      </div>
    );
  }

  function PaymentInformation() {
    const lastFourCardNumbers = subscription?.lastFourCardNumbers ?? '****';
    const cardExpirationDate = subscription?.cardExpirationDate
      ? subscription.cardExpirationDate
      : 'N/A';

    return (
      <div className='flex flex-col gap-4'>
        {/* sub header */}
        <h3 className='text-2xl font-normal'>Payment Method</h3>
        {/* card information */}
        <div className='grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-0'>
          {/* card number */}
          <div className='flex flex-col gap-2'>
            <div className='text-[#6D727F] text-xl font-light'>Card Number</div>
            <div className='flex flex-row gap-1'>
              <div className='text-black text-xl'>**** **** ****</div>
              <div className='text-black text-xl'>{lastFourCardNumbers}</div>
            </div>
          </div>
          {/* card exp date */}
          <div className='flex flex-col gap-2'>
            <div className='text-[#6D727F] text-xl font-light'>
              Expiration Date
            </div>
            <div className='text-black text-xl'>{cardExpirationDate}</div>
          </div>
        </div>
      </div>
    );
  }

  function UpdateSubscriptionLink() {
    // stripe uses UTC support stripe questions subscription-charge-date
    const accessEnds = subscription?.subscriptionAccessEnds;
    // get current date in UTC
    const currentDateUTC = new Date().toISOString();
    // check cancelled subscription has not passed the next bill date
    const isCancelledSubscriptionAccessValid = accessEnds
      ? currentDateUTC <= accessEnds
      : false;

    // active subscription user can update cc info
    // cancelled subscription before next bill date user can reactivate subscription
    if (
      subscription?.status === 'active' ||
      isCancelledSubscriptionAccessValid
    ) {
      return (
        <div className='flex flex-col text-center'>
          <div>
            <button
              className={`text-xl text-blueOne hover:underline hover:cursor-pointer ${
                loadingCustomerPortal ? 'text-blueOne opacity-60' : ''
              }`}
              onClick={handleUpdateSubscription}
              disabled={loadingCustomerPortal}
            >
              {loadingCustomerPortal
                ? 'Processing...'
                : 'Update Subscription or Card'}
            </button>
          </div>
        </div>
      );
    }

    // user has no subscription OR
    // cancelled subscription after next bill date passed user will need to create a new subscription
    return (
      <div className='flex flex-col text-center'>
        <div>
          <Link
            to='/pricing-plans'
            className='text-xl text-blueOne hover:underline hover:cursor-pointer'
          >
            Update Subscription
          </Link>
        </div>
      </div>
    );
  }

  /* END - ACTIVE SUBSCRIPTION */

  /* CANCELLED SUBSCRIPTION */
  function CancelledPlanInformation() {
    const planType = subscription?.planType ?? 'N/A';
    const status = subscription?.status ?? 'N/A';
    const accessEnds = subscription?.subscriptionAccessEnds
      ? formatDate(subscription.subscriptionAccessEnds)
      : 'N/A';

    return (
      <div className='flex flex-col gap-4'>
        {/* sub header */}
        <h3 className='text-2xl font-normal'>Cancelled Plan</h3>
        {/* subscription information */}
        <div className='grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-0'>
          {/* type */}
          <div className='flex flex-col gap-2'>
            <div className='text-[#6D727F] text-xl font-light'>
              Subscription
            </div>
            <div className='text-black text-xl'>{planType}</div>
          </div>
          {/* status */}
          <div className='flex flex-col gap-2'>
            <div className='text-[#6D727F] text-xl font-light'>Status</div>
            <div className='text-black text-xl'>{status}</div>
          </div>
          {/* end date */}
          <div className='flex flex-col gap-2'>
            <div className='text-[#6D727F] text-xl font-light'>Access Ends</div>
            <div className='text-black text-xl'>{accessEnds}</div>
          </div>
        </div>
      </div>
    );
  }
  // skeleton while getting subscription from stripe
  if (loadingSubscription) {
    return <HeaderAndContentSkeleton />;
  }

  // error while fetching subscription
  if (errorSubscription) {
    return (
      <div className='flex flex-col gap-8'>
        <h1 className='text-2xl font-bold'>Subscription Management</h1>
        <h3 className='text-2xl font-normal'>Current Plan</h3>
        <div className='flex flex-col bg-red-300 rounded-2xl gap-6 px-4 py-10'>
          <span className='text-black text-2xl xs:text-3xl xs3:text-4xl font-light text-center'>
            Error getting subscription information
          </span>
        </div>
      </div>
    );
  }

  // user has never enrolled in a subscription
  if (userDoesNotHaveSubscription) {
    return (
      <div className='flex flex-col gap-8'>
        <h1 className='text-2xl font-bold'>Subscription Management</h1>
        <h3 className='text-2xl font-normal'>Current Plan</h3>
        <PlanCard />
        <UpdateSubscriptionLink />
      </div>
    );
  }

  // user has cancelled a subscription
  if (userHasCancelledSubscription) {
    return (
      <div className='flex flex-col gap-8'>
        <h1 className='text-2xl font-bold'>Subscription Management</h1>
        <h3 className='text-2xl font-normal'>Current Plan</h3>
        <PlanCard />
        <CancelledPlanInformation />
        <UpdateSubscriptionLink />
      </div>
    );
  }

  // user has active subscription
  return (
    <div className='flex flex-col gap-8'>
      <h1 className='text-2xl font-bold'>Subscription Management</h1>
      <h3 className='text-2xl font-normal'>Current Plan</h3>
      <PlanCard />
      <BillingInformation />
      <PaymentInformation />
      <UpdateSubscriptionLink />
    </div>
  );
}
